import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './Pages/LandingPage';
import { summaryData as initialSummaryData } from './_summaryData';
import PageWrapperComponent from './Components/GlobalComponent/PageWrapperComponent';
import LoadingComponent from './Components/GlobalComponent/LoadingComponent';
import FallbackComponent from './Components/GlobalComponent/FallBackComponent';
import { preloadImages } from './utils/preloadImages';
import ContactFormComponent from './Components/GlobalComponent/ContactFormComponent';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] = useState(initialSummaryData);

  const updateActiveSummary = (path) => {
    const updatedSummaryData = summaryData.map(data => ({
      ...data,
      isActive: path === (data.id === 2 ? '/' : `/${data.pageTitle.replace(/\s+/g, '-').toLowerCase()}`)
    }));
    setSummaryData(updatedSummaryData);
  };

  const handleTitleClick = (id) => {
    const updatedSummaryData = summaryData.map(data => ({
      ...data,
      isActive: data.id === id
    }));
    setSummaryData(updatedSummaryData);
  };

  useEffect(() => {
    const images = summaryData.reduce((acc, item) => {
      // const bgImage = getComputedStyle(document.documentElement)
      //   .getPropertyValue(`--${item.bgImageClass.replace('sm:bg-', '')}`);
      // if (bgImage) acc.push(bgImage.trim().replace(/^url\(['"](.+)['"]\)$/, '$1'));
      if (item.bgMobImage) acc.push(item.bgMobImage);
      return acc;
    }, []);

    preloadImages(images).then(() => {
      setLoading(false);
    });
  }, [summaryData]);

  return (
    <Router>
      <PageWrapperComponent updateActiveSummary={updateActiveSummary}>
        <Routes>
          {summaryData.map((data) => (
            <Route
              key={data.id}
              path={data.id === 2 ? '/' : `/${data.pageTitle.replace(/\s+/g, '-').toLowerCase()}`}
              element={
                loading
                  ? <LoadingComponent />
                  : <LandingPage
                    summaryData={summaryData}
                    activeSummary={summaryData.find(item => item.id === data.id)}
                    handleTitleClick={handleTitleClick}
                  />
              }
            />
          ))}
          <Route path="*" element={<FallbackComponent />} />
        </Routes>
        <ContactFormComponent />
      </PageWrapperComponent>
    </Router>
  );
};

export default App;
