import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PageWrapperComponent = ({ children, updateActiveSummary }) => {
    const location = useLocation();
    const [previousPath, setPreviousPath] = useState('/')

    useEffect(() => {
        if (previousPath !== location.pathname) {
            updateActiveSummary(location.pathname);
            setPreviousPath(location.pathname)
        }
    }, [location.pathname, updateActiveSummary, previousPath]);

    return children;
};

export default PageWrapperComponent