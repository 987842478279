export const preloadImages = (images) => {
    return new Promise((resolve) => {
        const promises = images.map((image) => {
            return new Promise((res) => {
                const img = new Image();
                img.src = image;
                img.onload = res;
            });
        });
        Promise.all(promises).then(resolve);
    });
};
