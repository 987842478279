import React from 'react';
import NavBarComponent from '../Components/NavBarComponent/NavBarComponent';
import SummaryComponent from '../Components/SummaryComponent/SummaryComponent';
import FallbackComponent from '../Components/GlobalComponent/FallBackComponent';
import facebook from '../assets/icons/facebook-dark.svg';
import linkedin from '../assets/icons/linkedin-dark.svg';
import twitter from '../assets/icons/twitter-X-dark.svg';


const LandingPage = ({ summaryData, activeSummary, handleTitleClick }) => {
    return (
        <div className={`${activeSummary ? activeSummary.bgImageClass : ''} w-full min-h-screen sm:min-h-dvh bg-cover bg-center bg-no-repeat sm:pt-14 pt-10 relative`}>
            <NavBarComponent summaryData={summaryData} onTitleClick={handleTitleClick} />
            {activeSummary ? (
                <SummaryComponent activeSummary={activeSummary} />
            ) : (
                <FallbackComponent />
            )}
            <div className='block sm:hidden absolute bottom-0'>
                <img src={activeSummary.bgMobImage} alt="demo bg" />
                <div className='w-full bg-yellow py-4 flex justify-center items-center gap-20'>
                    <a href="https://www.facebook.com/p/Phaneesh-Murthy-100070532401799/?paipv=0&eav=AfbJpeEu-1ntEU7XjbCHF6G_Txunxbi276F2ur9NBDsob4_tqQOFXKY2S89eBt6yP6A&_rdr" target='_blank' rel="noreferrer">
                        <img src={facebook} className='w-3' alt="" />
                    </a>
                    <a href="https://www.linkedin.com/in/phaneesh-murthy-190528272/" target='_blank' rel="noreferrer">
                        <img src={linkedin} className='w-5' alt="" />
                    </a>
                    <a href="https://x.com/i/flow/login?redirect_after_login=%2FPhaneesh_Murthy" target='_blank' rel="noreferrer">
                        <img src={twitter} className='w-4' alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
