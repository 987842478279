import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/icons/pm-logo.svg';
import primentorincLogo from '../../assets/images/primentorinc-logo.png'
import logoBlack from '../../assets/icons/pm-logo-black.svg';

const NavBarComponent = ({ summaryData, onTitleClick }) => {

  const [dropDrownOpen, setDropDrownOpen] = useState(false);

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleSidebarClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setSidebarOpen(false);
      setIsClosing(false);
    }, 800);
  };

  // Default order for desktop
  const desktopNavTitles = summaryData.map((data) => (
    <li
      key={data.id}
      className={`${data.isActive ? 'sm:text-navBlack' : 'sm:text-darkGrey'} text-navBlack font-bold sm:font-semibold select-none cursor-pointer`}
      onClick={() => {
        onTitleClick(data.id);
      }}
    >
      <Link to={data.id === 2 ? '/' : `/${data.pageTitle.replace(/\s+/g, '-').toLowerCase()}`}>
        {data.pageTitle}
      </Link>
    </li>
  ));

  // Sorted order for mobile: [2, 3, 4, 0, 1]
  const orderedIds = [2, 3, 4, 0, 1];
  const mobileSummaryData = [...summaryData].sort((a, b) => {
    return orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id);
  });

  const mobileNavTitles = mobileSummaryData.map((data) => (
    <li
      key={data.id}
      className={`${data.isActive ? 'sm:text-navBlack' : 'sm:text-darkGrey'} text-navBlack font-bold sm:font-semibold select-none cursor-pointer`}
      onClick={() => {
        onTitleClick(data.id);
        handleSidebarClose();
      }}
    >
      <Link to={data.id === 2 ? '/' : `/${data.pageTitle.replace(/\s+/g, '-').toLowerCase()}`}>
        {data.pageTitle}
      </Link>
    </li>
  ));

  return (
    <nav className='container flex mx-auto 2xl:gap-24 xl:gap-20 md:gap-20 sm:gap-8 xl:justify-center'>
      <div className='hidden sm:block'>
        <Link to="/" onClick={() => onTitleClick(2)} className=''>
          <img src={logo} alt="Phaneesh Murthy Logo" className='sm:max-w-16 h-auto md:max-w-24 lg:max-w-28 2xl:max-w-32' />
        </Link>
      </div>
      <div className='flex sm:hidden w-full justify-start items-center pl-5 -translate-y-3'>
        <Link to="/" onClick={() => onTitleClick(2)} className=''>
          <img src={logo} alt="Phaneesh Murthy Logo" className='sm:max-w-16 h-auto md:max-w-24 lg:max-w-28 2xl:max-w-32' />
        </Link>
      </div>
      <div className='sm:hidden absolute right-4 top-8'>
        <button
          data-collapse-toggle="navbar-mobile"
          type="button"
          aria-controls="navbar-mobile"
          aria-expanded={isSidebarOpen}
          onClick={() => setSidebarOpen(!isSidebarOpen)}
        >
          <svg width="22" height="23" viewBox="0 0 22 23" fill="#FAA932" xmlns="http://www.w3.org/2000/svg">
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 22 0)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 22 8.43408)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 22 16.8681)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 13.6553 0)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 13.6553 8.43408)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 13.6553 16.8681)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 5.3103 0)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 5.3103 8.43408)" />
            <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 5.3103 16.8681)" />
          </svg>
        </button>
      </div>
      <div className='hidden sm:block' id="navbar-mobile">
        <ul className='flex flex-row sm:p-2 md:p-4 xl:gap-20 lg:gap-12 sm:gap-6 mx-auto bg-grey items-center md:px-12 sm:px-6 rounded-full font-semibold text-50 md:text-50 lg:text-75 xl:text-100 2xl:text-200 uppercase'>
          {desktopNavTitles}
          <div className='w-fit relative'>
            <p className='text-2xl p-1 cursor-pointer' onClick={() => setDropDrownOpen(!dropDrownOpen)}>⋮</p>
            <div id='dropdown' className={`${dropDrownOpen ? '' : 'hidden'} z-[15] w-fit absolute -bottom-16 -right-10 bg-white shadow-lg rounded-lg p-2 transition-all duration-300 ease-in-out`}>
              <div className='cursor-pointer flex items-center justify-start font-bold gap-2' onClick={() => {
                window.open("https://www.primentorinc.com/", '_blank');
                setDropDrownOpen(false);
              }}>
                <img src={primentorincLogo} className='w-4' alt="" />
                <p className='sm:text-nowrap leading-5' href="" target='_blank'>
                  Go To Primentorinc
                </p>
                <svg fill="#000000" width="12px" height="12px" viewBox="-8.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <title>right</title>
                  <path d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"></path>
                </svg>
              </div>
            </div>
            <div className={`w-full fixed inset-0 opacity-0 z-10 ${dropDrownOpen ? 'block' : 'hidden'}`} onClick={() => setDropDrownOpen(false)} />
          </div>
        </ul>
      </div>

      {/* Sidebar for Mobile */}
      {isSidebarOpen && (
        <div className={`fixed inset-0 z-20 ${isClosing ? 'animate-navClose' : 'animate-navOpen'}`} >
          <div className='fixed right-2 top-2 rounded-2xl h-[calc(100vh-1rem)] w-[80%] sm:w-[60%] bg-yellow shadow-lg p-10 z-20'>
            <button
              className='absolute top-6 right-6'
              onClick={handleSidebarClose}
            >
              <svg className='fill-navBlack' width="22" height="23" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg">
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 22 0)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 22 8.43408)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 22 16.8681)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 13.6553 0)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 13.6553 8.43408)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 13.6553 16.8681)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 5.3103 0)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 5.3103 8.43408)" />
                <rect width="5.31034" height="5.31034" transform="matrix(-1 0 0 1 5.3103 16.8681)" />
              </svg>
            </button>
            <div className=''>
              <Link to="/" onClick={() => {
                onTitleClick(2);
                handleSidebarClose();
              }}>
                <img src={logoBlack} alt="Phaneesh Murthy Logo" className='sm:max-w-16 h-auto md:max-w-24 lg:max-w-32' />
              </Link>
              <ul className='flex flex-col pt-10 space-y-8 mb-10'>
                {mobileNavTitles}
              </ul>
              <div className='sm:min-w-60 flex items-center justify-start font-bold gap-2' onClick={() => window.open("https://www.primentorinc.com/", '_blank')}>
                <img src={primentorincLogo} className='w-4' alt="" />
                <a className='sm:text-nowrap leading-5' href="" target='_blank'>
                  Go To Primentorinc
                </a>
                <svg fill="#000000" width="12px" height="12px" viewBox="-8.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <title>right</title>
                  <path d="M7.75 16.063l-7.688-7.688 3.719-3.594 11.063 11.094-11.344 11.313-3.5-3.469z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div className='absolute inset-0 bg-black opacity-0 z-10' onClick={handleSidebarClose}></div>
        </div>
      )}
    </nav>
  );
};

export default NavBarComponent;
