import React, { useState } from 'react';

const ContactFormButton = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [submitMessage, setSubmitMessage] = useState('');

    const toggleForm = () => {
        setIsFormVisible(!isFormVisible);
        setSubmitMessage('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Construct the mailto URL
        const subject = encodeURIComponent('Contact From Browser');
        const body = encodeURIComponent(`Name: ${formData.name}\nEmail: ${formData.email}\n\nMessage:\n${formData.message}`);
        const mailtoUrl = `mailto:info@sostech.in?subject=${subject}&body=${body}`;

        // Open the default email client
        window.location.href = mailtoUrl;

        // Show success message and reset form
        setSubmitMessage('Email client opened. Please send the email from your client.');
        setFormData({ name: '', email: '', message: '' });

        // Close the form after 5 seconds
        setTimeout(() => {
            setIsFormVisible(false);
            setSubmitMessage('');
        }, 5000);
    };

    return (
        <>
            {isFormVisible && (
                <div className="fixed bottom-32 sm:bottom-20 right-4 max-w-md bg-white p-6 rounded-lg shadow-lg">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                rows="4"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            ></textarea>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow"
                            >
                                Submit
                            </button>
                        </div>
                        {submitMessage && (
                            <div className="mt-2 text-sm text-center text-green-600">
                                {submitMessage}
                            </div>
                        )}
                    </form>
                </div>
            )}
            <button
                onClick={toggleForm}
                className="fixed bottom-16 sm:bottom-4 right-4 bg-black text-white p-4 rounded-full shadow-lg hover:bg-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                aria-label={isFormVisible ? "Close contact form" : "Open contact form"}
            >
                {isFormVisible ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                )}
            </button>
        </>
    );
};

export default ContactFormButton;