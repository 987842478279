// src/components/LoadingComponent/LoadingComponent.js
const LoadingComponent = () => {
    return (
        <div className="flex justify-center items-center h-screen w-full">
            <div className="loader"></div>
        </div>
    );
};

export default LoadingComponent;
